export const loanProviders = {
  all: [
    { name: 'lendfoundry', order: 1 },
    { name: 'lendpro', order: 2 },
    { name: 'homeproFinance' },
  ],
  lendproOnly: [{ name: 'lendpro', order: 1 }, { name: 'homeproFinance' }],
  lendfoundryOnly: [{ name: 'lendfoundry', order: 1 }],
  smarthomepayOnly: [{ name: 'smarthomepay', order: 1 }],
};
