import { environment } from '../../environments/environment';

export const equifaxConfig: any = {
  grant_type: 'client_credentials',
  scope: {
    INSTATOUCH_ACCOUNT: 'https://api.equifax.com/business/instatouch-account',
    INSTATOUCH_EMPLOYMENT: 'https://api.equifax.com/business/instatouch-employment',
    INSTATOUCH_ID: 'https://api.equifax.com/business/instatouch-identity',
    INSTATOUCH_OFFERS: 'https://api.equifax.com/business/instatouch-offer',
    INSTATOUCH_PLUS: 'https://api.equifax.com/business/instatouch-plus',
    INSTATOUCH_PAY: 'https://api.equifax.com/business/instatouch-pay',
    TALENT_REPORTS_EMPLOYMENT: 'https://api.equifax.com/business/staffing/v2/talent-reports/employment',
    TALENT_REPORTS_ID: 'https://api.equifax.com/business/staffing/v2/talent-reports/identity',
    VERIFICATION_EXCHANGE: 'https://api.equifax.com/verification-exchange-api/v2',
    TWN_ID_VERIFICATION: 'https://api.equifax.com/business/verifications/twn/v1/identity-verifications:r',
    SCOREONLY: 'https://api.equifax.com/business/auto/credit/v2/equifax-scores',
    OFAC_ALERT: 'https://api.equifax.com/business/auto/ofac-alert/v2/screenings',
    WALLET_INSIGHTS: 'https://api.equifax.com/business/walletInsights',
    POWERLEAD_PLUS: 'https://api.equifax.com/business/auto/powerlead/v2/powerleadplus',
    POWERLEAD_CHECK: 'https://api.equifax.com/business/auto/powerlead/v2/powerleadcheck',
    POWERLEAD_IDENTITY: 'https://api.equifax.com/business/auto/powerlead/v2/powerleadidentity',
    POWERLEAD_QUALIFY: 'https://api.equifax.com/business/auto/powerlead/v2/powerleadqualify',
    POWERLEAD_OFFER: 'https://api.equifax.com/business/auto/powerlead/v2/offer-attributes',
    INTERCONNECT: 'https://api.equifax.com/business/interconnect/v1/decision-orchestrations',
    INSIGHT_GATEWAY: 'https://api.equifax.com/business/insightgateway/v1/data-orchestrations'
  },
  credit: {
    MAX: 850,
    A: !environment.RUN_NEW_CREDIT_POLICY ? 650 : 675,
    B: !environment.RUN_NEW_CREDIT_POLICY ? 625 : 650,
    C: !environment.RUN_NEW_CREDIT_POLICY ? 600 : 620,
    MIN: 280
  },
  renterFee: 599
};
