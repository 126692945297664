import { resourceType } from "app/configs/resource.type";
import { environment } from "environments/environment";
import { currentLoanProvider, financeApprovedText } from "./finance";

export const getUserRoles = (allRole) => {
  const isPermissionSet = localStorage.getItem('isPermissionSet');
  const allRoles = JSON.parse(localStorage.getItem('allRoles'));
  if (allRole && (isAdminRole(allRole) || allRole.implicitRolesForUser.includes('role:3g-upgrade')) &&
    !isPermissionSet) 
    {
    return true;
  }
  return false;
}

export const multiRoles = (allRoles)=>{
  if(allRoles.implicitRolesForUser.length > 1){
    return true;
  }
  return false;
}

export const anyOutsideSaleRole = (allRoles)=>{
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-dfw')){
    return true;
  }
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-hou')){
    return true;
  }
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-aus')){
    return true;
  }
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-san')){
    return true;
  }
  return false;
}

export const isAnyOutsideSales = (userRole)=>{
  if(
    userRole === 'role:outside-sales-dfw' || 
    userRole === 'role:outside-sales-hou' ||
    userRole === 'role:outside-sales-aus' ||
    userRole === 'role:outside-sales-san' 
    ){
      return true;
  }
  return false;
}

export const isOutsideSalesDfw = (allRoles)=>{
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-dfw')){
    if( 
      allRoles.implicitRolesForUser.includes('role:phone-sales') ||
      allRoles.implicitRolesForUser.includes('role:showroom-sales')){
        return true;
    }
    return true;
  }
  return false;
}

export const isOutsideSalesHou = (allRoles)=>{
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-hou')){
    if(
      allRoles.implicitRolesForUser.includes('role:phone-sales') ||
      allRoles.implicitRolesForUser.includes('role:showroom-sales')){
        return true;
    }
    return true;
  }
  return false;
}

export const isOutsideSalesAus = (allRoles)=>{
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-aus')){
    if(
      allRoles.implicitRolesForUser.includes('role:phone-sales') ||
      allRoles.implicitRolesForUser.includes('role:showroom-sales')){
        return true;
    }
    return true;
  } 
  return false;
}

export const isOutsideSalesSan = (allRoles)=>{
  if(allRoles.implicitRolesForUser.includes('role:outside-sales-san'
  )){
    if(
      allRoles.implicitRolesForUser.includes('role:phone-sales') ||
      allRoles.implicitRolesForUser.includes('role:showroom-sales')){
        return true;
    }
    return true;
  } 
  return false;
}

export const isUserRole = (allRoles) => {
  if (allRoles.implicitRolesForUser.includes('role:outside-sales-dfw')) {
    return true;
  }
  if (allRoles.implicitRolesForUser.includes('role:outside-sales-hou')) {
    return true;
  }
  if (allRoles.implicitRolesForUser.includes('role:outside-sales-aus')) {
    return true;
  }
  if (allRoles.implicitRolesForUser.includes('role:outside-sales-san')) {
    return true;
  }
  if (allRoles.implicitRolesForUser.includes('role:showroom-sales')) {
    return true;
  }
  if (allRoles.implicitRolesForUser.includes('role:3g-upgrade')) {
    return true;
  }
  if(allRoles.implicitRolesForUser.includes('role:onit')){
    return true;
  }
  if(allRoles.implicitRolesForUser.includes('role:phone-sales')){
    return true;
  }
  return false
}

export const isAdminRole  = (allRoles)=>{
  if(allRoles.implicitRolesForUser.includes('role:admin')){
    return true;
  }
  return false;
}
export const shouldShowAddButton = (allRole) => {
  const userRoles = localStorage.getItem('userRole');
  if(allRole.implicitResources[`${userRoles}`].hasOwnProperty('res:self-gen')){
    return true;
  }
  return false;
}

export const sidebarFiltering = (currentUser, filter, role='') => {
  const userRole = localStorage.getItem('userRole');
  const currentUserPermission = getCurrentUserPermission(userRole);
  if(userRole){
    if (role === 'user') {
      if (currentUserPermission.isShowroom) {
        filter.push({ name: 'Show Room', value: 'showroom' });
      }
      if (currentUserPermission.isRenewal) {
        filter.push({ name: '3G Renewal', value: 'renewal' });
      }
      if (currentUserPermission.isPhoneSales) {
        filter.push({ name: 'Phone Sales', value: 'phone_sales' });
      }
      if (currentUserPermission.isOnit) {
        filter.push({ name: 'OnIt Home Sales', value: 'onit_home_sales' });
      }
      return filter;
    }
    else {
      if (currentUserPermission.isShowroom && filter.name === 'Show Room') {
        return filter;
      } else if (currentUserPermission.isRenewal && filter.name === '3G Renewal') {
        return filter;
      } else if (currentUserPermission.isPhoneSales && filter.name === 'Phone Sales') {
        return filter;
      } 
      else if (currentUserPermission.isOnit && filter.name === 'OnIt Home Sales') {
        return filter
      } 
      else if (
        filter.name !== 'Show Room' &&
        filter.name !== '3G Renewal' &&
        filter.name !== 'Phone Sales' &&
        filter.name !== 'OnIt Home Sales'
        ) {
          return filter;
        }
    }
  }
  else if (
    filter.name !== 'Show Room' &&
    filter.name !== '3G Renewal' &&
    filter.name !== 'Phone Sales' &&
    filter.name !== 'OnIt Home Sales'
    ) {
      return filter;
    } 
}
    
export const shouldShowPreviousAddress = (leadType) => {
  const resources = getResource(leadType);
  if(resources.includes('prev-addr-required')){
    return environment.ENABLE_PREVIOUS_ADDRESS ? true : false;
  }
  return false;
}

export const shouldShowIo = (leadType) => {
  const resources = getResource(leadType);
  if(resources.includes('intro-offer')){
    return true;
  }
  return false;
}

export const shouldCheckiSoftpull = (leadType) => {
  const resources = getResource(leadType);
  if(resources.includes('isoftpull-check')){
    return true;
  }
  return false;
}

export const shouldShowConvenienceFee = (leadType) => {
  const resource = getResource(leadType);
  if(resource.includes('convenience-fee')){
    return true;
  }
  return false;
}

export const shouldShowLendFoundry = (leadType) => {
  const resources = getResource(leadType);
  if(resources.includes('shp-finance')){
    return true;
  }
  return false;
}

export const isLendfoundryApproved = (currentCustomer) => {
  if (currentCustomer.lendProAccountStatus && currentCustomer.lendProAccountStatus === 'approved') {
    return true
  }
  return false
}

export const ifFinanceChosen = (currentCustomer) => {

  if (
    currentCustomer.loanType === currentLoanProvider &&
    currentCustomer.lfAttempted &&
    !currentCustomer.lfLoanAmountSubmitted) {
    if (currentCustomer.lendProStatus &&
      currentCustomer.lendProStatus === financeApprovedText) {
      return true;
    }
    return false;
  }
  return false;

}
export const showRoomLeadHelper = (leadType: string): boolean => {
  const leadResource = resourceType[leadType];
  return leadResource === 'res:preselect' ? true : false;

}

export const includeTax = (leadType) => {
  const resources = getResource(leadType);
  if(resources.includes('incl-tax')){
    return true;
  }
  return false;
}

export const selfOnitLeadHelper = (leadType) => {
  const resourceLead = resourceType[leadType];
  return resourceLead === 'res:self-gen' || 'res:onit' ? true : false;
}

export const showroomRenewalCheck = (leadType) => {
  const leadResource = resourceType[leadType];
  if (leadResource === 'res:preselect' || leadResource === 'res:3g-upgrade') {
    return true
  }
  return false
}

export const canDiscountRmr = (leadType)=>{
  const resource = getResource(leadType);
  if(resource.includes('discount-rmr')){
    return true;
  }
  return false;
}

export const shouldShowRemote = (leadType)=>{
  const resource = getResource(leadType);
  if(resource.includes('remote')){
    return true;
  }
  return false;
}

export const contractTerms = (leadType)=>{
  const resource = getResource(leadType);
  let terms=[]
  if(resource.includes('contract_term_1')){
    terms.push('12');
  }
  if(resource.includes('contract_term_2')){
    terms.push('24');
  }
  if(resource.includes('contract_term_3')){
    terms.push('36')
  }
  if(resource.includes('contract_term_5')){
    terms.push('60')
  }
  return terms;
}

export const shouldShowSkipAci = (leadType)=>{
  const resource = getResource(leadType);
  if(resource.includes('skip-aci')){
    return true;
  }
  return false;
}


export const showCompleteDate = (leadType) => {
    const resource = getResource(leadType);
    if(resource.includes('completion-date-on-contract')){
      return  true     
    }
    return false;
}

export const getCurrentUserPermission = (userRole:string)=>{
  let currentUser = {
    isShowroom : false,
    isRenewal : false,
    isOnit : false,
    isPhoneSales : false  
  };
  if(userRole){
    const allRole = JSON.parse(localStorage.getItem('allRoles'));
    if(allRole.implicitResources[`${userRole}`].hasOwnProperty('res:preselect')){
      currentUser.isShowroom = true;
      if(userRole === 'role:outside-sales-dfw' && !allRole.implicitRolesForUser.includes('role:showroom-sales')){
        currentUser.isShowroom = false;
      }
      if(userRole === 'role:outside-sales-hou' && !allRole.implicitRolesForUser.includes('role:showroom-sales')){
        currentUser.isShowroom = false;
      }
      if(userRole === 'role:outside-sales-aus' && !allRole.implicitRolesForUser.includes('role:showroom-sales')){
        currentUser.isShowroom = false;
      }
      if(userRole === 'role:outside-sales-san' && !allRole.implicitRolesForUser.includes('role:showroom-sales')){
        currentUser.isShowroom = false;
      }
    }
    if (allRole.implicitResources[`${userRole}`].hasOwnProperty('res:3g-upgrade')) {
      currentUser.isRenewal = true;
    }
    if (allRole.implicitResources[`${userRole}`].hasOwnProperty('res:onit')) {
      currentUser.isOnit = true;
    }
    if(allRole.implicitResources[`${userRole}`].hasOwnProperty('res:phone-sales')){
      currentUser.isPhoneSales = true;
    }
    return currentUser;
  }
  return currentUser;
}

const getResource =(leadType)=>{
  const allRoles = JSON.parse(localStorage.getItem('allRoles'));
  const userRole = localStorage.getItem('userRole');
  const resourceLead = resourceType[leadType];
  const resources =allRoles.implicitResources[`${userRole}`][`${resourceLead}`];
  return resources
}