import { environment } from "environments/environment";

export const currentLoanProvider = environment.FINANCE_PROVIDER === "SHP" ? "smarthomepay" : "lendfoundry";

export const financeApprovedText = environment.FINANCE_PROVIDER === "SHP" ? "finalization" : "OfferApproved"
;
export const finalAmountSubmittedResponseText = environment.FINANCE_PROVIDER === "SHP" ? 
    "Final Amount added and submitted to SmartHomePay." :
    "Final Amount Updated" ;
export const loanAppCreatedText = environment.FINANCE_PROVIDER === "SHP" ?
    "Loan App Created." :
    "Loan App Created";
export const manualReviewText = environment.FINANCE_PROVIDER === "SHP" ?
    "underwriting":
    "ManualReview"

export const getPendingArray = ()=>{
    if(environment.FINANCE_PROVIDER === "SHP"){
        return ["pending","acceptance"];
    }
    if(environment.FINANCE_PROVIDER === "LF"){
        return ["ApplicationSubmitted", "ApplicationProcessing"]
    }
}

export const getApprovedArray = ()=>{
    if(environment.FINANCE_PROVIDER === "SHP"){
        return [
            "underwriting",
            "finalization",
            "funded"
        ];
    }
    if(environment.FINANCE_PROVIDER === "LF"){
        return [
            "OfferApproved",
            "ManualReview",
            "FinalAmountSubmitted",
            "PendingCustomerSignature",
            "FinancingAccepted",
            "Funded",
        ];
    }
}

export const getFailedArray = ()=>{
    if(environment.FINANCE_PROVIDER === "SHP"){
        return ["terminated"];
    }
    if(environment.FINANCE_PROVIDER === "LF"){
        return ["Declined"];
    }
}

export const isFinanceStatusDeclined = (status:string)=>{
    let declinedStatus = ['terminated'];
    if(environment.FINANCE_PROVIDER === "SHP"){
        declinedStatus = ['terminated'];
    }
    if(environment.FINANCE_PROVIDER === "LF"){
        declinedStatus = ['Expired','Rejected','NotInterested','Declined'];
    }
    return declinedStatus.includes(status);
}

export const isFinanceStatusFunded = (status:string)=>{
    let fundedStatus = ['funded'];
    if(environment.FINANCE_PROVIDER === "SHP"){
        fundedStatus = ['funded'];
    }
    if(environment.FINANCE_PROVIDER === "LF"){
        fundedStatus = ['FinancingAccepted','Funded'];
    }
    return fundedStatus.includes(status);

}